export default [{
  header: 'Metrics Platform',
},
{
  title: 'Metrics',
  icon: 'BarChart2Icon',

  children: [
    {
      title: 'CDN',
      route: 'dashboard-cdn',

    },
  ],
},

]
