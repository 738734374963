export default [{
  header: 'Metrics Platform',
},
{
  title: 'Metrics',
  icon: 'BarChart2Icon',

  children: [{
    title: 'Plataforma',
    route: 'dashboard-statistics',

  },
  {
    title: 'CDN',
    route: 'dashboard-cdn',

  },
  ],
},

]
